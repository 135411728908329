import React from "react";

import Layout from "components/Layout";
import Intro from "components/Intro";
import { SEO } from "components/SEO";

const NotFoundPage = () => {
	return (
		<Layout pageName="404">
			<SEO title="Blue Medical 404" />
			<Intro>
				<h1 className="mono">
					The requested URL was not found on this server.
				</h1>
				<p>404 Error</p>
			</Intro>
		</Layout>
	);
};

export default NotFoundPage;
